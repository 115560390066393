import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  name: string
  placeholder?: string
  required?: boolean
  value?: string
  onBlur?: FocusEventHandler<HTMLTextAreaElement>
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onFocus?: FocusEventHandler<HTMLTextAreaElement>
}

export const Textarea = memo(
  forwardRef<HTMLTextAreaElement, Props>(function Textarea(
    { name, placeholder, required, value, onBlur, onChange, onFocus },
    ref,
  ) {
    return (
      <>
        {placeholder ? <Label>{placeholder}</Label> : null}

        <Container
          ref={ref}
          name={name}
          required={required}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
        />
      </>
    )
  }),
)

Textarea.displayName = 'Textarea'

const Container = styled.textarea`
  ${({ theme }) => {
    return css`
      width: 100%;
      height: 9.375rem;
      background: transparent;
      border: 0.0625rem solid ${rgba(theme.colors.variants.neutralDark3, 0.2)};
      color: ${theme.colors.variants.neutralDark3};
      font-family: ${theme.fontFamily.paragraph};
      font-size: 1.125rem;
      margin-top: 0.875rem;
      padding: 1.5rem;
      resize: none;
      text-align: left;
    `
  }}
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 1.6875rem;
  margin-top: 0.875rem;
`
