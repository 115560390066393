import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Close } from 'app/components/Common/Close'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { ModalGallery } from 'app/components/Common/ModalGallery'
import { Spinner } from 'app/components/Common/Spinner'
import useLockedBody from 'app/utils/useLockedBody'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Head } from './Head'
import { Info } from './Info'
import { Props as ServiceProps } from './Service'

export interface Props {
  description?: string
  equipment?: ServiceProps[]
  images?: ImageProps[]
  languageCode: string
  pax?: string
  setup?: ServiceProps[]
  size?: string
  thumbnails?: ImageProps[]
  title?: string
}

export const MeetingRoom = memo(function MeetingRoom({
  description,
  equipment,
  images,
  languageCode,
  pax,
  setup,
  size,
  thumbnails,
  title,
}: Props) {
  const containerRef = useRef<any>()
  const [_locked, setLocked] = useLockedBody()

  const [currentSlide, setCurrentSlide] = useState(0)
  const [clickedSlide, setClickedSlide] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])
  const [nextTwoLoaded, setNextTwoLoaded] = useState<boolean[]>([])

  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)

  let counter = 0

  const [sliderRef, galleryRef] = useKeenSlider({
    loop: false,
    slides: {
      perView: 1.8,
      spacing: 24,
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1399px)': {
        slides: {
          perView: 1.4,
          spacing: 24,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1.1,
          spacing: 24,
        },
      },
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true
    const newNextTwoLoaded = [...nextLoaded]
    newNextTwoLoaded[currentSlide + 2] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)
    setNextTwoLoaded(newNextTwoLoaded)

    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [currentSlide, galleryRef, sliderRef, modalGalleryStatus])

  return (
    <Container ref={containerRef}>
      <Head
        description={description}
        languageCode={languageCode}
        pax={pax}
        size={size}
        title={title}
      />

      {images && images.length > 0 ? (
        <>
          <Wrapper>
            <Slider className="grab-cursor" ref={sliderRef}>
              {uniq(thumbnails).map((item, index) => {
                counter = counter + 1

                return (
                  <Slide
                    className="keen-slider__slide"
                    data-counter={counter}
                    key={index}
                    onClick={(e) => {
                      if (!modalGalleryStatus) {
                        setLocked(true)
                        setModalGalleryStatus(true)
                      } else {
                        setLocked(false)
                        setModalGalleryStatus(false)
                      }

                      const realClickedSlide =
                        Number(e.currentTarget.getAttribute('data-counter')) - 1

                      setClickedSlide(realClickedSlide)
                      setTimeout(() => {
                        setClickedSlide(null)
                      }, 100)
                    }}
                  >
                    <Image
                      alt={
                        loaded[index] ||
                        nextLoaded[index] ||
                        nextTwoLoaded[index]
                          ? item.alt
                          : ''
                      }
                      animationStart={
                        loaded[index] ||
                        nextLoaded[index] ||
                        nextTwoLoaded[index]
                          ? true
                          : false
                      }
                      {...(loaded[index] ||
                      nextLoaded[index] ||
                      nextTwoLoaded[index]
                        ? item
                        : null)}
                    />
                  </Slide>
                )
              })}
            </Slider>

            {images.length > 1 ? (
              <Arrows>
                <Arrow
                  disabled={currentSlide === 0}
                  onClick={(e) =>
                    e.stopPropagation() || galleryRef.current?.prev()
                  }
                />
                <Arrow
                  direction="R"
                  disabled={currentSlide === images.length - 1}
                  onClick={(e) =>
                    e.stopPropagation() || galleryRef.current?.next()
                  }
                />
              </Arrows>
            ) : null}
          </Wrapper>

          <Info
            equipment={equipment}
            languageCode={languageCode}
            setup={setup}
          />

          <Modal className={modalGalleryStatus ? 'open' : undefined}>
            <Close
              onClick={() => {
                setLocked(false)
                setModalGalleryStatus(false)
                setClickedSlide(0)
              }}
              variant="gallery"
            />
            <ModalGallery
              clickedSlide={clickedSlide}
              containerRef={containerRef}
              images={images}
              isOpen={modalGalleryStatus}
            />
          </Modal>
        </>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 8.75rem 0 9.375rem;
  position: relative;

  @media (max-width: 1199px) {
    padding: 5.625rem 1.5rem;
  }
`

const Wrapper = styled.div`
  padding-right: 1.5rem;
  padding-left: 17.5vw;
  position: relative;

  @media (max-width: 1199px) {
    padding: 0;
  }
`

const Slider = styled.div`
  display: flex;
  aspect-ratio: 2.5;
  outline: none;

  @media (max-width: 1023px) {
    aspect-ratio: 1.6;
  }
`

const Slide = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight5};
  overflow: hidden;
  pointer-events: none;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  @media (max-width: 1199px) {
    pointer-events: visible;
  }
`

const Arrows = styled.div`
  > div {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    &:first-of-type {
      left: 5.417vw;
    }
    &:last-of-type {
      right: 5.417vw;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
