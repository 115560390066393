import styled from '@emotion/styled'
import { Check } from 'app/components/Icons'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '1.875rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {icon ? (
        <Icon src={icon} alt={label} width="30" height="30" />
      ) : (
        <Check />
      )}

      {label ? <Label dangerouslySetInnerHTML={{ __html: label }} /> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  width: 33.333%;
  margin-top: 1.4375rem;
  padding-left: 2.5rem;
  position: relative;

  svg {
    width: 1.625rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 1.25rem;
  }
`

const Icon = styled.img`
  width: 1.625rem;
  height: auto;
  max-height: 1.875rem;
  position: absolute;
  top: 0.25rem;
  left: 0;
`

const Label = styled.div`
  max-width: 12.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 1.6875rem;

  @media (max-width: 1199px) {
    max-width: none;
  }
`
