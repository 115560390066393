import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  equipment?: ServiceProps[]
  languageCode: string
  setup?: ServiceProps[]
}

export const Info = memo(function Info({
  equipment,
  languageCode,
  setup,
}: Props) {
  return (
    <Container>
      {equipment ? (
        <Wrapper>
          <FadeInUp>
            <Label>{useVocabularyData('equipment', languageCode)}</Label>
          </FadeInUp>

          <List row wrap>
            {equipment.map((item, index) => (
              <Service key={index} {...item} />
            ))}
          </List>
        </Wrapper>
      ) : null}

      {setup ? (
        <Wrapper>
          <FadeInUp>
            <Label>{useVocabularyData('setup', languageCode)}</Label>
          </FadeInUp>

          <List row wrap>
            {setup.map((item, index) => (
              <Service key={index} {...item} />
            ))}
          </List>
        </Wrapper>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  margin-top: 1.875rem;
  padding: 0 17.5vw;

  @media (max-width: 1199px) {
    padding: 0 0 0 0.9375rem;
  }
`

const Wrapper = styled.div`
  margin-top: 3.5rem;
  &:first-of-type {
    margin-top: 0;
  }
`

const Label = styled.div`
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1.875rem;
`

const List = styled(FlexBox)``
