import '../../theme/datepicker.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Input } from 'app/components/Common/Input'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { FormData, MeetingFormSenderBackend } from 'app/utils/MeetingFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { de, enUS, fr, it } from 'date-fns/esm/locale'
import { rgba } from 'emotion-rgba'
import { isLeft } from 'fp-ts/lib/These'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'

registerLocale('de', de)
registerLocale('en', enUS)
registerLocale('fr', fr)
registerLocale('it', it)

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  description?: string
  languageCode: string
  languagePrefix?: string | null
  onMeetingFormSendToBackend: MeetingFormSenderBackend
  title?: string
}

export const MeetingForm = memo(function MeetingForm({
  description,
  languageCode,
  languagePrefix,
  onMeetingFormSendToBackend,
  title,
}: Props) {
  /*
   * Datepicker configuration
   */
  const [date, setDate] = useState<any>()
  const [dateValue, setDateValue] = useState('')

  /*
   * Form data
   */
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onMeetingFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Meeting Form',
          })
      }
    },
    [isSendingForm, onMeetingFormSendToBackend],
  )

  const { register, formState, handleSubmit, setValue } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }

    if (dateValue) {
      setValue('date', dateValue)
    }
  }, [formState.errors, dateValue])

  return (
    <Container id="meeting-form">
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} variant="form" />

        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}

        <Wrapper row wrap space="between">
          <Field>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              placeholder={`${useVocabularyData('name', languageCode)}*`}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.lastname !== undefined}
              isDirty={formState.dirtyFields.lastname}
              placeholder={`${useVocabularyData('lastname', languageCode)}*`}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.email !== undefined}
              isDirty={formState.dirtyFields.email}
              placeholder={`${useVocabularyData('email', languageCode)}*`}
              type="email"
              required={true}
              {...register('email', {
                required: true,
                validate: (email) => EMAIL_REGEX.test(email),
              })}
            />
          </Field>
          <Field>
            <Input
              type="text"
              placeholder={useVocabularyData('phone', languageCode)}
              {...register('phone')}
            />
          </Field>
          <Field className="picker-field">
            <DatePicker
              className={
                formState.errors.date !== undefined && !date
                  ? 'error'
                  : undefined
              }
              dateFormat="dd/MM/yyyy"
              disabledKeyboardNavigation={true}
              locale={languagePrefix || 'en'}
              {...register('date', {
                required: true,
              })}
              onChange={(date) => {
                if (date) {
                  setDate(date)
                  setDateValue(
                    date.toLocaleDateString('en-US', {
                      day: 'numeric',
                      month: '2-digit',
                      year: 'numeric',
                    }),
                  )
                }
              }}
              onFocus={(e) => (e.target.readOnly = true)}
              placeholderText={`${useVocabularyData('date', languageCode)}*`}
              minDate={new Date()}
              required={true}
              selected={date}
              withPortal={
                typeof window !== 'undefined' && window.innerWidth > 767
                  ? false
                  : true
              }
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.pax !== undefined}
              isDirty={formState.dirtyFields.pax}
              placeholder={`${useVocabularyData('pax', languageCode)}*`}
              type="text"
              required={true}
              {...register('pax', {
                required: true,
                validate: (pax) => pax.length > 0,
              })}
            />
          </Field>
          <Field className="large-field">
            <Textarea
              placeholder={useVocabularyData('message', languageCode)}
              {...register('message')}
            />
          </Field>
          <Checkboxes>
            <Checkbox
              error={formState.errors.privacy_policy !== undefined}
              label={useVocabularyData('form-policy-text', languageCode)}
              required={true}
              {...register('privacy_policy', {
                required: true,
              })}
            />
          </Checkboxes>
        </Wrapper>

        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}

        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData('form-confirmation-message', languageCode)}
            title={useVocabularyData(
              'form-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}

        <Input
          name="submit"
          type="submit"
          value={useVocabularyData('send-request', languageCode)}
          variant="submit"
        />
      </Form>
    </Container>
  )
})

const Container = styled.section`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  padding: 9.375rem 17.5vw;
  position: relative;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.5rem;
  }
`

const Form = styled.form`
  max-width: 62.5rem;
  background: url('illustration-17.svg') no-repeat right top;
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralDark3};
  margin: auto;
  padding-left: 1.25rem;
  position: relative;

  @media (max-width: 1199px) {
    max-width: none;
    background: none;
    padding-left: 0.9375rem;
  }
`

const Title = styled.h2`
  max-width: 33.9375rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    max-width: none;
    font-size: 1.875rem;
    line-height: 1.875rem;
  }
`

const Description = styled.div`
  max-width: 39.1875rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.3125rem;
  line-height: 1.875rem;
  margin-top: 0.9375rem;

  @media (max-width: 1199px) {
    max-width: none;
  }
`

const Wrapper = styled(FlexBox)`
  margin-top: 2.25rem;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 3.125rem;
      background: url('calendar.svg') no-repeat calc(100% - 0.0625rem) center;
      border-bottom: 0.0625rem solid
        ${rgba(theme.colors.variants.neutralDark3, 0.2)};
      color: ${({ theme }) => theme.colors.variants.neutralDark3};
      cursor: pointer;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 1.125rem;
      &.error {
        border-color: ${({ theme }) => theme.colors.variants.danger};
      }

      &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        opacity: 1;
      }
      &::-moz-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        opacity: 1;
      }
      &::placeholder {
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        opacity: 1;
      }
    }
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    background-color: ${({ theme }) => theme.colors.variants.neutralDark3};
    color: ${({ theme }) => theme.colors.variants.neutralLight5};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralLight5, 0.85)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .react-datepicker__current-month {
    font-family: ${({ theme }) => theme.fontFamily.heading};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.variants.primaryLight};
    color: ${({ theme }) => theme.colors.variants.neutralLight5};
  }

  .react-datepicker__day:focus {
    background-color: ${({ theme }) =>
      theme.colors.variants.neutralDark3} !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight5} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${({ theme }) => theme.colors.variants.primaryLight};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.variants.neutralLight5} !important;
    opacity: 0.3;
  }
`

const Field = styled.div`
  width: calc(50% - 1.25rem);
  margin-top: 1.125rem;
  text-align: left;
  &.large-field {
    width: 100%;
  }
  &.picker-field {
    width: calc(50% - 1.25rem);
  }

  @media (max-width: 1199px) {
    width: 100%;
    &.picker-field {
      width: 100%;
    }
  }
`

const Checkboxes = styled.div``
